import React,{useState,useEffect,useMemo} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import BlockIcon from '@mui/icons-material/Block';
import ImageSelect from './ImageSelect';
import ProductUploader from './ProductUploader';
import ProductRelease from './ProductRelease';
import FileDrop from './FileDrop';
import Toast from './Toast';
import Ajax from './Ajax';
import YesNo from './YesNo';
import InputField from './InputField';
import InputSuggest from './InputSuggest';
import Loading from './Loading';
import {Inc,Product,ImageData,MaxProductFileSize,MaxProductFiles,MaxProductNameLength,MinProductPrice,MaxProductPrice,MaxProductIntroductionLength,MaxProductFileNameLength,MaxProductImageSize,MaxProductImageWidth,MaxProductUrlLength,MaxProductImageMax,MaxProductThumbnailSize,MaxProductThumbnailWidth,AvailableFileTypes,MaxProductAboutServiceFileLength,MaxProductAboutSampleFileLength,MaxProductSampleFileSize,MaxProductSampleFiles,MaxProductServiceFiles,MaxProductTagLength,ProductCopyrights,ProductPublisheds} from './Inc';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';
import Tools from './Tools';

interface ProductInputProps {
    isOpen:boolean,
	close(updated:boolean):void,
	productId?:string,
}

const ProductInput:React.FC<ProductInputProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const classes = useStyles();
	const defaultStates = {
		imageUpdated:false,
		fileUpdated:false,
		isLoading: false,
		isUploader: false,
		isUpdated: false,
		isExchange: false,
		isServiceExchange: false,
		isSampleExchange: false,
		isImageExchange: false,
		isRelease: false,
		enableExchange: true,
		newProductId: "",
		suggestedTags: [],
	};
	const defaultFiles = {
		list:[] as File[],
		hasFileError: false,
	};
	const defaultImages = [] as ImageData[];

	const [states, setStates] = useState(defaultStates);
	const [product,setProduct] = useState(Inc.defaultProduct());
	const [files,setFiles] = useState(defaultFiles);
	const [serviceFiles,setServiceFiles] = useState(defaultFiles);
	const [sampleFiles,setSampleFiles] = useState(defaultFiles);
	const [images,setImages] = useState(defaultImages);
	const [thumbnails,setThumbnails] = useState(defaultImages);
	const [errors,setErrors] = useState(Inc.defaultProductError());

	const [getAway,setGetAway] = useState(false);
	const [toast,setToast] = useState('');
	const [isExit,setIsExit] = useState(false);
	
	const onChangeValue = (name:keyof Product) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		if( name==="price" ){
			value = value.replace(/[０-９]/g, function (s) {
				return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
			})			
			value = value.replace(/[^0-9]/g, '');
			let price = 0;
			if( value!=='' ){
				price = parseInt(value);
			}
			//setStates({ 
			//	...states,
			//	isUpdated:true,
			//	product:{...states.product,price:price}
			//});
			setProduct({...product,price:price})
			setStates({...states,isUpdated:true});
			//console.log("1");
		} else {
			//setStates({ 
			//	...states,
			//	isUpdated:true,
			//	product:{...states.product,[name]:value}
			//});
			setProduct({...product,[name]:value})
			setStates({...states,isUpdated:true});
			//console.log("2");
		}
	};

	//const onToggleCopyright = (index:number) => {
	//	let id:'copyright1'|'copyright2'|'copyright3' = 'copyright1';
	//	if( index===2 ){
	//		id = 'copyright2';
	//	}
	//	if( index===3 ){
	//		id = 'copyright3';
	//	}
	//	setProduct({...product,[id]:!product[id]});
	//	setStates({...states,isUpdated:true});
	//}

	const onChangeCopyright1 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = (event.target as HTMLInputElement).value;
		const id = 'copyright1';
		setProduct({...product,[id]:v==='1' ? true : false});
		setStates({...states,isUpdated:true});
	}

	const onChangeCopyright2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = (event.target as HTMLInputElement).value;
		const id = 'copyright2';
		setProduct({...product,[id]:v==='1' ? true : false});
		setStates({...states,isUpdated:true});
	}

	const onChangeCopyright3 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = (event.target as HTMLInputElement).value;
		const id = 'copyright3';
		setProduct({...product,[id]:v==='1' ? true : false});
		setStates({...states,isUpdated:true});
	}

	const onChangePublished = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = (event.target as HTMLInputElement).value;
		setProduct({...product,published:v==='1' ? true : false});
		setStates({...states,isUpdated:true});
	}

	const fileNames = useMemo(()=>{
		const fileSizes = product.fileSizes.split("\t");
		return product.fileNames.split("\t").map((name:string,index:number)=>{
			const key = "filename"+index;
			return (
				<ListItem key={key}>
					<ListItemText primary={name} secondary={Tools.FileSize(parseInt(fileSizes[index]))} key={key}/>
				</ListItem>
			)
		})}
	,[product.fileNames])

	const serviceFileNames = useMemo(()=>{
		const serviceFileSizes = product.serviceFileSizes.split("\t");
		return product.serviceFileNames.split("\t").map((name:string,index:number)=>{
			const key = "servicefilename"+index;
			return (
				<ListItem key={key}>
					<ListItemText primary={name} secondary={Tools.FileSize(parseInt(serviceFileSizes[index]))} key={key}/>
				</ListItem>
			)
		})}
	,[product.fileNames])

	const sampleFileNames = useMemo(()=>{
		const sampleFileSizes = product.sampleFileSizes.split("\t");
		return product.sampleFileNames.split("\t").map((name:string,index:number)=>{
			const key = "samplefilename"+index;
			return (
				<ListItem key={key}>
					<ListItemText primary={name} secondary={Tools.FileSize(parseInt(sampleFileSizes[index]))} key={key}/>
				</ListItem>
			)
		})}
	,[product.fileNames])

	const updateImages = (images:ImageData[])=>{
		setStates({...states,isUpdated:true,imageUpdated:true});
		setImages(images);
		//console.log("3");
	}
	const cancelImage = ()=>{
		setStates({...states,isImageExchange:true,isUpdated:true});
		//console.log("4");
	}

	const updateFiles = (fs:File[],hasError:boolean)=>{
		//console.log("5");
		//console.log(states);
		setStates({...states,isUpdated:true});
		setFiles({list:[...fs],hasFileError:hasError});
		//ref.current.files = files;
		//setStates({...states,hasFileError:hasError});
	}	
	const updateServiceFiles = (fs:File[])=>{
		//setStates({...states,isUpdated:true,serviceFiles:[...files]});
		//console.log("6");
		setStates({...states,isUpdated:true});
		setServiceFiles({list:[...fs],hasFileError:false});
	}
	const updateSampleFiles = (fs:File[])=>{
		//console.log("7");
		//console.log(states);
		//setStates({...states,isUpdated:true,sampleFiles:[...files]});
		setStates({...states,isUpdated:true});
		setSampleFiles({list:[...fs],hasFileError:false});
	}
	const exitYes = ()=>{
		setIsExit(false);
		props.close(false);
	}
	const exitNo = ()=>{
		setIsExit(false);
	}
	const close = ()=>{
		props.close(false);
	}
	const updateThumbnails = (images:ImageData[])=>{
		setStates({...states,isUpdated:true,imageUpdated:true});
		setThumbnails(images);
		//console.log("8");
	}
	const cancelThumbnail = ()=>{
		setStates({...states,isUpdated:true});
		//console.log("9");
	}

	const validate = ()=>{
		if( !userState.user ){
			return;
		}

		let err:boolean = false;
		let error = Inc.defaultProductError();
		const name = product.name.trim();
		if( name==="" ){
			error["name"] = "商品名を入力してください";
			err = true;
		} else if( name.length>MaxProductNameLength ){
			error["name"] = "商品名を"+MaxProductNameLength+"文字以内にしてください";
			err = true;
		}

		for( let i=0; i<files.list.length; i++ ){
			if( files.list[i].name.length>MaxProductFileNameLength ){
				error["fileNames"] = "各ファイル名は"+Tools.comma(MaxProductFileNameLength)+"文字以内にしてください";
				err = true;
			}
		}

		if( product.price<MinProductPrice ){
			error["price"] = Tools.comma(MinProductPrice)+"円以上にしてください";
			err = true;
		} else if( product.price>MaxProductPrice ){
			error["price"] = Tools.comma(MaxProductPrice)+"円以下にしてください";
			err = true;
		}

		if( product.introduction==="" ){
			error["introduction"] = "商品の説明を入力してください";
			err = true;
		} else if( product.introduction.length>MaxProductIntroductionLength ){
			error["introduction"] = "商品の説明を"+Tools.comma(MaxProductIntroductionLength)+"文字以内にしてください";
			err = true;
		}

		if( product.aboutServiceFile.length>MaxProductAboutServiceFileLength ){
			error["aboutServiceFile"] = "追加ファイルの説明を"+Tools.comma(MaxProductAboutServiceFileLength)+"文字以内にしてください";
			err = true;
		}


		for( let i=0; i<serviceFiles.list.length; i++ ){
			if( serviceFiles.list[i].name.length>MaxProductFileNameLength ){
				error["serviceFileNames"] = "ファイル名は"+Tools.comma(MaxProductFileNameLength)+"文字以内にしてください";
				err = true;
			}
		}

		const url = product.url.trim();
		if( url!=="" ){
			if( url.length>MaxProductUrlLength ){
				error["url"] = "リンクを"+MaxProductUrlLength+"文字以内にしてください";
				err = true;
			} else if( url.substr(0,8)!=="https://" ) {
				error["url"] = "リンクはhttps://から始まる必要があります";
				err = true;
			} else if( !Tools.validateUrl(url) ){
				error["url"] = "リンクが正しくありません";
				err = true;
			}
		}

		if(err){
			setToast('赤字の項目を修正してください');
			setErrors(error);
			//console.log("10");
			return;
		}
		setStates({...states,isUploader:true});
		//console.log("11");
	}

	const closeUploader = ()=>{
		setStates({...states,isUploader:false});
		//console.log("12");
	}

	const finishUploader = (id:string,permission:boolean)=>{
		if( props.productId ){
			setStates({...states,isUploader:false});
			//console.log("13");
			props.close(true);
		} else {
			//permission -> 販売開始の許可（支払情報のメールチェックが終わっている）
			setStates({...states,newProductId:id,isUploader:false,isRelease:permission});
			//console.log("14");
			if(!permission){
				props.close(true);
			}
		}
	}

	const closeRelease = ()=>{
		setStates({...states,isRelease:false});
		//console.log("15");
		props.close(true);
	}

	const openExchange = ()=>{
		//setIsExchange(true);
		setStates({...states,isExchange:true});
		//console.log("16");
	}
	const closeExchange = ()=>{
		setStates({...states,isExchange:false});
		setFiles(defaultFiles);
		//console.log("17");
	}

	const openServiceExchange = ()=>{
		//setIsserviceExchange(true);
		setStates({...states,isServiceExchange:true,isUpdated:true});
		//console.log("18");
	}
	const closeServiceExchange = ()=>{
		//setIsserviceExchange(false);
		setStates({...states,isServiceExchange:false});
		setServiceFiles(defaultFiles);
		//console.log("19");
	}

	const openSampleExchange = ()=>{
		setStates({...states,isSampleExchange:true,isUpdated:true});
		//console.log("20");
	}
	const closeSampleExchange = ()=>{
		setStates({...states,isSampleExchange:false});
		setSampleFiles(defaultFiles);
		//console.log("21");
	}


	const cancel = ()=>{
		if( states.isUpdated ){
			setIsExit(true);
		} else {
			props.close(false);
		}
	}

	const closeToast = ()=>{
		setToast('');
	}

	const load = async ()=>{
		setProduct(Inc.defaultProduct());
		setFiles(defaultFiles);
		setServiceFiles(defaultFiles);
		setSampleFiles(defaultFiles);
		setImages(defaultImages);
		setThumbnails(defaultImages);
		setErrors(Inc.defaultProductError());

		if( !props.productId || !userState.user ){
			setStates({...defaultStates,isLoading:false});
			//console.log("22");
			return;
		}
		setStates({...defaultStates,isLoading:true});
		//console.log("23");
		const ajax = new Ajax();
		const res = await ajax.getProduct(userState.user.uid,props.productId);
		if( res.result==="OK" ){
			let enableExchange = true;
			if( res.product.lastSale!=='' ){
				enableExchange = false;
			}
			//setStates({...defaultStates,product:res.product,images:Inc.getProductImages(res.product.imageKeys),thumbnails:Inc.getProductImages(res.product.thumbnailKey),enableExchange:enableExchange,isLoading:false});
			setStates({...defaultStates, enableExchange:enableExchange, suggestedTags:res.suggestedTags, isLoading:false});
			setImages(Inc.getProductImages(res.product.imageKeys));
			setThumbnails(Inc.getProductImages(res.product.thumbnailKey));
			setProduct(res.product);
			//console.log(res.product);
			//console.log(res.suggestedTags);
		} else {
			setGetAway(true);
		}
	}

	useEffect(()=>{
		if(!props.isOpen){
			return;
		}
		if( !userState.user ){
			setGetAway(false);
			return;
		}
		load();
		window.scrollTo(0,0);
	},[props.isOpen,userState.user]);

	if( !props.isOpen ){
		return null;
	}

	if( getAway ){
		props.close(false);
		return null;
	}

	let userId = "";
	if( userState.user ){
		userId = userState.user.uid;
	}

	let disabled = false;
	if( !props.productId || states.isExchange ){
		if( files.list.length===0 ){
			//console.log("disabled1");
			//console.log(files);
			disabled = true;
		}
	}
	if( !states.isUpdated || product.name==="" || product.introduction==="" || product.price===0 || files.hasFileError || images.length===0 ){
		//console.log("disabled2");
		disabled = true;
	}

	//let numImages = 0;
	//for( let i=0; i<states.images.length; i++ ){
	//	if( states.images[i].source!=="deleted" ){
	//		numImages ++;
	//	}
	//}

	//console.log(states.thumbnails);


	return (
		<Dialog
			fullScreen
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogContent style={{padding:"0",margin:"0",backgroundColor:'#F5F5F5'}}>

				<AppBar position="fixed" style={{backgroundColor:`#FFFFFF`}}>
					<Toolbar variant="dense" style={{backgroundColor:`#FFFFFF`}}>
						<Box style={{flex:'1'}}/>
						<Button variant="text" color="primary" onClick={cancel} style={{marginRight:`0.5em`}}>もどる</Button>
						<Button variant="contained" color="primary" disabled={disabled} onClick={validate}>登録</Button>
					</Toolbar>
				</AppBar>

				<Container maxWidth="lg" style={{paddingTop:"4em",paddingBottom:"6em",backgroundColor:'#FFFFFF'}}>

					<Grid container spacing={1}>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">イメージ画像
							<span className={classes.formRequired}>必須</span>
							</Typography>
							<Box className={classes.formExplanation}>
								販売ページの最初に表示される画像です。<br/>
								6枚まで登録できます。<br/>
								PNG / JPEG / GIF<br/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={9} md={8}>
							<ImageSelect title="イメージ画像" message="ここにファイルをドラッグ＆ドロップするか、タップしてファイルを選択してください。" images={images} maxSize={MaxProductImageSize} maxWidth={MaxProductImageWidth} maxFiles={MaxProductImageMax} setImages={updateImages} cancel={cancelImage}/>
							{images.length>0 ? (
							<FormHelperText>※右上の<SettingsIcon className={classes.withIcon}/>をタップすると順序の変更や削除ができます</FormHelperText>
							):(
							<FormHelperText>※{MaxProductImageSize}MB以上か幅または高さが{Tools.comma(MaxProductImageWidth)}pixel以上の画像は自動的に圧縮されます</FormHelperText>
							)}
						</Grid>
						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">商品名
							<span className={classes.formRequired}>必須</span>
							</Typography>
							<Box className={classes.formExplanation}>
							
							</Box>

						</Grid>
						<Grid item xs={12} sm={9} md={8}>
							<InputField label="商品名" onChange={onChangeValue("name")} value={product.name} error={errors.name} opt={{maxLength:MaxProductNameLength}}/>
							<FormHelperText>※{MaxProductNameLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">価格
							<span className={classes.formRequired}>必須</span>
							</Typography>
							<Box className={classes.formExplanation}>
							
							</Box>

						</Grid>
						<Grid item xs={12} sm={9} md={8}>
							<InputField label="価格" onChange={onChangeValue("price")} value={product.price} error={errors.price} opt={{endAdornment:<InputAdornment position="end">円</InputAdornment>,fullWidth:false}}/>
							<FormHelperText>※{MinProductPrice}円～{Tools.comma(MaxProductPrice)}円（税込）</FormHelperText>
						</Grid>
						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">販売ファイル
							<span className={classes.formRequired}>必須</span>
							</Typography>
							<Box className={classes.formExplanation}>
							購入したお客様がダウンロードするファイルです。<br/>
							登録できるファイルの種類やサイズについてはご利用ガイドの「商品登録」をご覧下さい。
							</Box>

						</Grid>
						<Grid item xs={12} sm={9} md={8}>
						{(props.productId && product.fileIds!=="" && !states.isExchange) ? (
							<List dense={true} className={classes.formDropzone}>
							{fileNames}
							</List>
						):(
							<React.Fragment>
							<FileDrop title="販売ファイル" message="ここにファイルをドラッグ＆ドロップするか、タップしてファイルを選択してください。" error={errors.fileNames!==""} maxFileSize={MaxProductFileSize} maxFiles={MaxProductFiles} maxFileNameLength={MaxProductFileNameLength} availableTypes={Object.keys(AvailableFileTypes)} onUpdate={updateFiles}/>
							{errors.fileNames!=="" &&
							<Box className={classes.formError}>{errors.fileNames}</Box>
							}
							<FormHelperText>※最大{MaxProductFiles}ファイル、各{Tools.FileSize(MaxProductFileSize)}以内、ファイル名は半角{MaxProductFileNameLength}文字以内</FormHelperText>
							</React.Fragment>
						)}
						{props.productId && 
							<div style={{marginTop:'0.5em',textAlign:'right'}}>
							{states.isExchange ? (
							<Button variant="outlined" color="inherit" onClick={closeExchange}>
								販売ファイル変更中止
							</Button>
							):(
							<Button variant="outlined" color="inherit" onClick={openExchange} disabled={!states.enableExchange}>
								<AutorenewIcon/>販売ファイル変更
							</Button>
							)}
							</div>
						}
							<Divider className={classes.formValidator}/>
							<InputField label="商品の紹介文" onChange={onChangeValue("introduction")} value={product.introduction} error={errors.introduction} opt={{type:'text',rows:10,maxLength:MaxProductIntroductionLength}}/>
							<FormHelperText>※{Tools.comma(MaxProductIntroductionLength)}文字以内</FormHelperText>
						</Grid>

						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>


						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">サンプルファイル</Typography>
							<Box className={classes.formExplanation}>
							購入検討中のお客様のためのサンプルファイルです。<br/>
							販売ページからいつでも誰でもダウンロードできます。<br/>
							</Box>
						</Grid>

						<Grid item xs={12} sm={9} md={8}>
						{(props.productId && product.sampleFileIds!=="" && !states.isSampleExchange) ? (
							<List dense={true} className={classes.formDropzone}>
							{sampleFileNames}
							</List>
						):(
							<React.Fragment>
							<FileDrop title="サンプルファイル" message="ここにファイルをドラッグ＆ドロップするか、タップしてファイルを選択してください。" maxFileSize={MaxProductSampleFileSize} maxFileNameLength={MaxProductFileNameLength} maxFiles={MaxProductSampleFiles} availableTypes={Object.keys(AvailableFileTypes)} onUpdate={updateSampleFiles}/>
							{errors.sampleFileNames!=="" &&
							<Box className={classes.formError}>{errors.sampleFileNames}</Box>
							}
							<FormHelperText>※{Tools.FileSize(MaxProductSampleFileSize)}以内の{MaxProductSampleFiles}ファイルのみ、ファイル名は{MaxProductFileNameLength}文字以内</FormHelperText>
							</React.Fragment>
						)}
						{props.productId && product.sampleFileIds!=="" &&
							<div style={{marginTop:'0.5em',textAlign:'right'}}>
							{states.isSampleExchange ? (
							<Button variant="outlined" color="inherit" onClick={closeSampleExchange}>
								サンプルファイル変更中止
							</Button>
							):(
							<Button variant="outlined" color="inherit" onClick={openSampleExchange}>
								<AutorenewIcon/>サンプルファイル削除・変更
							</Button>
							)}
							</div>
						}
						{false &&
							<React.Fragment>
							<Divider className={classes.formValidator}/>
							<InputField label="サンプルファイルの説明" onChange={onChangeValue("aboutSampleFile")} value={product.aboutSampleFile} error={errors.aboutSampleFile} opt={{type:'text',rows:4,maxLength:MaxProductAboutSampleFileLength,required:false}}/>
							<FormHelperText>※{Tools.comma(MaxProductAboutSampleFileLength)}文字以内</FormHelperText>
							</React.Fragment>
						}
						</Grid>

						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">リンク</Typography>
							<Box className={classes.formExplanation}>
							商品の詳しい説明やサンプルが見られる外部サイトのURL
							</Box>
						</Grid>

						<Grid item xs={12} sm={9} md={8}>
							<InputField label="リンク" onChange={onChangeValue("url")} value={product.url} error={errors.url} opt={{placeholder:"https://",maxLength:MaxProductUrlLength}}/>
							<FormHelperText>※{MaxProductUrlLength}文字以内</FormHelperText>
						</Grid>

						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">追加ファイル</Typography>
							<Box className={classes.formExplanation}>
							販売ファイルと同様に購入したお客様がダウンロードするファイルですが、こちらはいつでも削除・変更ができます。<br/>
							販促キャンペーンや販売ファイルの内容の追加・アップデートなど必要に応じてご利用下さい。<br/>
							</Box>
						</Grid>

						<Grid item xs={12} sm={9} md={8}>
						{(props.productId && product.serviceFileIds!=="" && !states.isServiceExchange) ? (
							<List dense={true} className={classes.formDropzone}>
							{serviceFileNames}
							</List>
						):(
							<React.Fragment>
							<FileDrop title="追加ファイル" message="ここにファイルをドラッグ＆ドロップするか、タップしてファイルを選択してください。" maxFileSize={MaxProductFileSize} maxFileNameLength={MaxProductFileNameLength} maxFiles={MaxProductServiceFiles} availableTypes={Object.keys(AvailableFileTypes)} onUpdate={updateServiceFiles}/>
							{errors.serviceFileNames!=="" &&
							<Box className={classes.formError}>{errors.serviceFileNames}</Box>
							}
							<FormHelperText>※{Tools.FileSize(MaxProductFileSize)}以内の{MaxProductServiceFiles}ファイルのみ、ファイル名は{MaxProductFileNameLength}文字以内</FormHelperText>
							</React.Fragment>
						)}
						{(props.productId && product.serviceFileIds!=="") &&
							<div style={{marginTop:'0.5em',textAlign:'right'}}>
							{states.isServiceExchange ? (
							<Button variant="outlined" color="inherit" onClick={closeServiceExchange}>
								追加ファイル変更中止
							</Button>
							):(
							<Button variant="outlined" color="inherit" onClick={openServiceExchange}>
								<AutorenewIcon/>追加ファイル削除・変更
							</Button>
							)}
							</div>
						}
							<Divider className={classes.formValidator}/>
							<InputField label="追加ファイルの説明" onChange={onChangeValue("aboutServiceFile")} value={product.aboutServiceFile} error={errors.aboutServiceFile} opt={{type:'text',rows:4,maxLength:MaxProductAboutServiceFileLength,required:false}}/>
							<FormHelperText>※{Tools.comma(MaxProductAboutServiceFileLength)}文字以内</FormHelperText>
						</Grid>

						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">サムネイル画像</Typography>
							<Box className={classes.formExplanation}>
							商品一覧でサムネイルとして使われます。<br/>
							幅600pixelの横画像だと多くの環境でキレイに表示されるようです。
							</Box>

						</Grid>

						<Grid item xs={12} sm={9} md={8}>
							{thumbnails.length>0 &&
							<FormLabel>サムネイル画像</FormLabel>
							}
							<ImageSelect title="サムネイル画像" message="ドラッグ＆ドロップ、又はタップしてファイルを選択" images={thumbnails} maxWidth={600} maxFiles={1} setImages={updateThumbnails} cancel={cancelThumbnail}/>
							{thumbnails.length>0 ? (
							<FormHelperText>※右上の<CancelIcon className={classes.withIcon}/>をタップすると削除できます</FormHelperText>
							):(
							<FormHelperText>※PNG / JPEG / GIF、{MaxProductThumbnailSize}MB以上か幅または高さが{Tools.comma(MaxProductThumbnailWidth)}pixel以上の画像は自動的に圧縮されます</FormHelperText>
							)}
						</Grid>

						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">検索タグ</Typography>
							<Box className={classes.formExplanation}>
								商品のジャンルやカテゴリなどを分かりやすくするための検索タグを３つまでつけることができます
							</Box>
						</Grid>

						<Grid item xs={12} sm={9} md={8}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={4}>
									<InputSuggest
										label="検索タグ1"
										options={states.suggestedTags}
										value={product.tags[0]}
										maxLength={MaxProductTagLength}
										onChange = {(value)=>{
											const tags = product.tags ? product.tags : ['','',''];
											tags[0] = value;
											setProduct({...product,tags:tags});
											setStates({...states,isUpdated:true});
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<InputSuggest
										label="検索タグ2"
										options={states.suggestedTags}
										value={product.tags[1]}
										maxLength={MaxProductTagLength}
										onChange = {(value)=>{
											const tags = product.tags ? product.tags : ['','',''];
											tags[1] = value;
											setProduct({...product,tags:tags});
											setStates({...states,isUpdated:true});
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<InputSuggest
										label="検索タグ3"
										options={states.suggestedTags}
										value={product.tags[2]}
										maxLength={MaxProductTagLength}
										onChange = {(value)=>{
											const tags = product.tags ? product.tags : ['','',''];
											tags[2] = value;
											setProduct({...product,tags:tags});
											setStates({...states,isUpdated:true});
										}}
									/>
								</Grid>
							</Grid>
							<FormHelperText>※各{MaxProductTagLength}文字以内</FormHelperText>
						</Grid>

						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">グランドメニューへの掲載</Typography>
							<Box className={classes.formExplanation}>
							ほかの作家さんの商品と一緒に一覧表示される「グランドメニュー」に掲載しますか？<br/>
							</Box>
						</Grid>

						<Grid item xs={12} sm={9} md={8}>
							<RadioGroup row className={classes.formRadioBox} onChange={onChangePublished}>
								{['1','0'].map((id:string)=>{
									const key = "radio" + id;
									let checked = false;
									if( (product.published===true && id==='1') || (product.published!==true && id==='0') ){
										checked = true;
									}
									return (
										<FormControlLabel key={key} value={id} control={<Radio checked={checked}/>} label={ProductPublisheds[id]}/>
									)
								})}
							</RadioGroup>
						</Grid>


						<Grid item xs={12}>
							<Divider className={classes.formValidator}/>
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">著作権の扱い</Typography>
							<Box className={classes.formExplanation}>
								購入後に利用可能な範囲を指定できます。<br/>
								素材としての利用を目的とした画像や音声以外は、基本的にはすべて禁止で問題ありません。
							</Box>
						</Grid>

						<Grid item xs={12} sm={9} md={8}>
							<List dense style={{paddingTop:'0'}}>
								<ListItem sx={{paddingBottom:{xs:'6em',sm:'4em',md:'0em'},paddingRight:{xs:'1em',sm:'1em',md:'14em'},marginBottom:{xs:'0',sm:'1em'}}}>
									<ListItemText primary="改変" secondary="商品の一部を改変して利用（画像に文字を追加する・トリミングするなど）"/>
									<ListItemSecondaryAction sx={{marginTop:{xs:'2em',sm:'2em',md:'0em'}}}>
										<RadioGroup row className={classes.formRadioBox} onChange={onChangeCopyright1}>
											{['0','1'].map((id:string)=>{
												const key = "radio" + id;
												let checked = false;
												if( (product.copyright1===true && id==='1') || (product.copyright1!==true && id==='0') ){
													checked = true;
												}
												return (
													<FormControlLabel key={key} value={id} control={<Radio checked={checked}/>} label={ProductCopyrights[id]}/>
												)
											})}
										</RadioGroup>										
									</ListItemSecondaryAction>
								</ListItem>
								<ListItem sx={{paddingBottom:{xs:'6em',sm:'4em',md:'0em'},paddingRight:{xs:'1em',sm:'1em',md:'14em'},marginBottom:{xs:'0',sm:'1em'}}}>
									<ListItemText primary="商用利用" secondary="利益を得る目的で利用（商用作品を作る際の素材として利用するなど）"/>
									<ListItemSecondaryAction sx={{marginTop:{xs:'2em',sm:'2em',md:'0em'}}}>
										<RadioGroup row className={classes.formRadioBox} onChange={onChangeCopyright2}>
											{['0','1'].map((id:string)=>{
												const key = "radio" + id;
												let checked = false;
												if( (product.copyright2===true && id==='1') || (product.copyright2!==true && id==='0') ){
													checked = true;
												}
												return (
													<FormControlLabel key={key} value={id} control={<Radio checked={checked}/>} label={ProductCopyrights[id]}/>
												)
											})}
										</RadioGroup>										
									</ListItemSecondaryAction>

								</ListItem>
								<ListItem sx={{paddingBottom:{xs:'6em',sm:'4em',md:'0em'},paddingRight:{xs:'1em',sm:'1em',md:'14em'},marginBottom:{xs:'0',sm:'1em'}}}>
									<ListItemText primary="クレジット表記の省略" secondary="作者名などを表示しないで利用"/>
									<ListItemSecondaryAction sx={{marginTop:{xs:'2em',sm:'2em',md:'0em'}}}>
										<RadioGroup row className={classes.formRadioBox} onChange={onChangeCopyright3}>
											{['0','1'].map((id:string)=>{
												const key = "radio" + id;
												let checked = false;
												if( (product.copyright3===true && id==='1') || (product.copyright3!==true && id==='0') ){
													checked = true;
												}
												return (
													<FormControlLabel key={key} value={id} control={<Radio checked={checked}/>} label={ProductCopyrights[id]}/>
												)
											})}
										</RadioGroup>										
									</ListItemSecondaryAction>

								</ListItem>
							</List>
							<Box style={{padding:'1em',backgroundColor:'#F5F0F0',marginTop:'1em',fontSize:'90%',lineHeight:'1.5em',color:'#333333'}}>
								<h4 style={{margin:'0',padding:'0',marginBottom:'1em'}}><BlockIcon style={{verticalAlign:'middle',color:'red'}}/> 二次利用・再配布は原則として禁止です</h4>
								(例) 購入したイラストをそのまま、または一部改変したものを販売・配布する<br/>
								(例) 購入したイラストをそのまま、または一部改変したものをプリントしたＴシャツを販売する<br/>
							</Box>
						</Grid>

					</Grid>
					<Toast mes={toast} close={closeToast}/>
					<Loading isOpen={states.isLoading}/>
					<YesNo onYes={exitYes} onNo={exitNo} title="保存せずに終了" text="編集途中の内容は破棄されますがよろしいですか？" isOpen={isExit}/>
					<ProductUploader isOpen={states.isUploader} files={files.list} serviceFiles={serviceFiles.list} isServiceExchange={states.isServiceExchange} sampleFiles={sampleFiles.list} isSampleExchange={states.isSampleExchange} product={product} images={images} thumbnails={thumbnails} userId={userId} close={closeUploader} finish={finishUploader}/>
					<ProductRelease isOpen={states.isRelease} isFirst={true} close={closeRelease} productId={states.newProductId} available={false}/>

				</Container>
			</DialogContent>
		</Dialog>
	)
}

export default ProductInput;