import React,{useEffect} from 'react';
import {Link,Navigate,useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
//import Button from '@mui/material/Button';

import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import FaceIcon from '@mui/icons-material/Face';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PublicIcon from '@mui/icons-material/Public';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import PageTitle from './PageTitle';
import Multilines from './Multilines';
import {useStyles} from './useStyles';
import {MinMinAmount,MaxMinAmount,TransferFee,MaxProducts} from './Inc';
import Tools from './Tools';
//import ProductDetail from './ProductDetail';

interface GuideDetailProps {
    
}

interface HelpRelation {
	group: string,
	index: number,
}

interface HelpData {
	title: string,
	body: string,
	notice: boolean,
	relations: HelpRelation[]
}

const HelpDict:{[key:string]:HelpData[];}  = {
	"account":[
		{
			title:"ログインが必要です",
			body:"403adamskiで商品（ファイル）を販売するには、まずログインをしてから作家のプロフィールや商品を登録してください。<br/><br/>ログインの方法はSNSアカウント、パスワード、メールの3種類から選べます。",
			notice: false,
			relations:[{
				group:"profile",
				index:1,
			},{
				group:"account",
				index:2,
			},{
				group:"account",
				index:3,
			},{
				group:"account",
				index:4,
			},{
				group:"account",
				index:7,
			}],
		},
		{
			title:"SNSアカウントでログイン",
			body:"SNSアカウントはGoogleまたはTwitterが利用可能です。<br/><br/>複数のSNSアカウントを持っている場合は、どのアカウントでログインしたのかを忘れないようにご注意ください。",
			notice: false,
			relations:[{
				group:"account",
				index:1,
			},{
				group:"account",
				index:7,
			}],
		},
		{
			title:"メールでログイン",
			body:"「メールでログイン」を選択すると、入力したメールアドレスにメールが届きます。メールにはログイン用のURLが書かれていますので、そちらにアクセスすればログイン完了です。この手順をログインのたびに行ってください。<br/><br/>なお、SNSアカウントに登録してあるメールアドレスを入力しても、SNSアカウントでログインした場合とは別の扱いになりますので注意が必要です。",
			notice: false,
			relations:[{
				group:"account",
				index:1,
			},{
				group:"account",
				index:7,
			}],
		},
		{
			title:"パスワードでログイン",
			body:"あらかじめ登録したメールアドレスとパスワードでログインする方法です。<br/><br/>登録したメールアドレスとパスワードはあとから変更することもできますが、変更の際には現在のパスワードの入力も必要になりますので、うっかり忘れないように必ずメモをしておいてください。",
			notice: false,
			relations:[{
				group:"account",
				index:1,
			},{
				group:"account",
				index:7,
			}],
		},
		{
			title:"本人のみ利用可能です",
			body:"原則としてSNSアカウントおよびメールアドレスをお持ちの本人のみがご利用可能です。複数人でのご利用や第三者への譲渡・貸与はできませんのでご注意ください。",
			notice: false,
			relations:[],
		},
		{
			title:"重要な連絡はメールで",
			body:"売上金の入金に関する連絡などの重要なお知らせはすべてメールで行います。プロフィールの「入金情報と連絡先」に有効なメールアドレスを登録してご利用ください。<br/><br/>また、こちらからのメールがスパム扱いにならないように受信設定をお願いいたします。<br/><br/><b>403adamskiのメールアドレス</b><br/>noreply@403adamski.jp",
			notice: false,
			relations:[{
				group:"account",
				index:7,
			}],
		},
		{
			title:"ログインできなくなったら",
			body:"SNSアカウントやメールアドレスの失効などでログインができなくなってしまった場合は、以下の内容をお問い合わせフォームからお知らせください。<br/><br/>・会員名（本名）<br/>・プロフィールに登録されている作家名<br/>・プロフィールの「入金情報と連絡先」に登録されているメールアドレス<br/><br/>ご連絡をいただいてから１～２営業日程度で、ログインのためのパスワード等をメールでお知らせいたします。",
			notice: false,
			relations:[{
				group:"account",
				index:1,
			},{
				group:"account",
				index:6,
			}],
		},
		{
			title:"ログイン方法の変更",
			body:"アカウントメニューの「ログイン方法の変更」でログインに使用するSNSアカウントやメールアドレスを変更することができます。<br/><br/>ただし、すでに403adamskiで利用中のアカウントやメールアドレスに変更したり、複数のアカウントを統合したりすることはできません。",
			notice: false,
			relations:[],
		},
		{
			title:"削除と終了（退会）",
			body:"ログインをしただけでは会員登録は行われず、利用料金が発生することもありません。<br/><br/>プロフィールや商品を登録した後でご利用をやめる場合は「削除と終了」の手続を行ってください。<br/><br/>プロフィールや商品を登録していない場合は、「削除と終了」の手続は不要です。",
			notice: false,
			relations:[],
		},
	],
	"profile":[
		{
			title:"プロフィール登録",
			body:"商品の販売者・作家としてのプロフィールが必要です。必須項目は名前だけですが、自己紹介のテキストもなるべく入力してください。内容はあとからいつでも変更できます。",
			notice: false,
			relations:[{
				group: 'sale',
				index: 7,
			}],
		},
		{
			title:"入金情報と連絡先",
			body:"商品の販売を開始する前に「入金情報と連絡先」の登録を必ず行ってください。売上金の入金の際に必要となる他、重要な連絡はこちらに登録された連絡先に送られます。<br/><br/>「入金情報と連絡先」の内容に間違いや虚偽があると判明した場合、ご利用を停止させていただくこともありますのでご注意ください。",
			notice: false,
			relations:[{
				group: 'transfer',
				index: 4,
			},{
				group: 'sale',
				index: 1,
			}],
		},
		{
			title:"公開ページのURL",
			body:"プロフィールを登録すると、あなたの商品やプロフィールを見られる公開ページのURLが発行されます。このURLの後半部分（ユーザー名）は「プロフィール設定→公開設定」で変更可能ですので、自分の好きな文字に変更してご利用ください。",
			notice: false,
			relations:[{
				group: 'promotion',
				index: 1,
			}],
		},
		{
			title:"アバター画像",
			body:"プロフィールのアバター画像にはご自身が著作権を有する画像または著作権フリーの画像をご利用ください。第三者の著作権、商標権などの知的財産権その他の権利を侵害する画像が登録された場合、予告なくご利用を停止することがあります。",
			notice: false,
			relations:[],
		},
		{
			title:"自己紹介",
			body:"自己紹介のテキストは自由に設定できますが、法令若しくは公序良俗に違反するなど利用規約の禁止事項に触れる内容が含まれていた場合、予告なくご利用を停止することがあります。",
			notice: false,
			relations:[],
		},
	],
	"product":[
		{
			title:"登録できる商品数",
			body:"登録できる商品数は最大"+MaxProducts+"個です。さらに登録を行いたい場合は、不要な商品を削除してください。<br/><br/>ただし、この制限は今後様子を見ながら調整していく予定です。",
			notice: false,
			relations:[],
		},
		{
			title:"登録できるファイル数",
			body:"商品ごとに以下のファイルを登録することができます。<br/><br/>・<b>販売ファイル…</b>　最大5個<br/>・<b>サンプルファイル…</b>　1個<br/>・<b>追加ファイル…</b>　1個",
			notice: false,
			relations:[{
				group:'product',
				index:3,
			},{
				group:'product',
				index:4,
			},{
				group:'product',
				index:5,
			}],
		},
		{
			title:"販売ファイルとは",
			body:"販売ファイルとはその名の通り販売の対象となるファイルです。代金を支払ったお客様だけにダウンロードの権利があります。<br/><br/>1ファイルのサイズは最大200MBまでです。<br/><br/>誰かに購入されるまでは何度でも変更（差し替え）が可能です。",
			notice: false,
			relations:[{
				group:'product',
				index:2,
			},{
				group:'product',
				index:8,
			},],
		},
		{
			title:"サンプルファイルとは",
			body:"サンプルファイルは商品の販売ページからいつでも誰でもダウンロードできるファイルです。購入時の参考となりそうなファイルをご用意ください。<br/>※必須ではありません。<br/><br/>ファイルのサイズは最大50MBまでです。<br/><br/>このファイルは、いつでも変更（差し替え）することができます。",
			notice: false,
			relations:[{
				group:'product',
				index:2,
			},],
		},
		{
			title:"追加ファイルとは",
			body:"追加ファイルは販売ファイルと同様に購入したお客様がダウンロードするファイルですが、こちらはいつでも削除・変更ができます。<br/><br/>あくまで購入者へのサービスという位置付けのファイルです。販促キャンペーンや販売ファイルの内容の追加・アップデートなど必要に応じて登録してください。<br/><br/>商品Aの販売ファイルを商品Bの追加ファイルにしたりするような使い方もＯＫです。<br/><br/>登録できるファイルは1つだけで、サイズは最大200MBまでです。",
			notice: false,
			relations:[{
				group:'product',
				index:2,
			},],
		},
		{
			title:"ファイル名に使用できる文字",
			body:"ファイル名に全角文字（日本語文字）は使用できません。半角100文字以内のファイル名をつけてアップロードしてください。",
			notice: false,
			relations:[{
				group:'product',
				index:3,
			},{
				group:'product',
				index:4,
			},{
				group:'product',
				index:5,
			}],
		},
		{
			title:"ファイルタイプ",
			body:"登録（アップロード）できるファイルタイプは以下の通りです。<br/><br/><b>画像</b><br/>png<br/>jpg<br/>gif<br/>psd<br/>ai<br/><br/><b>文書</b><br/>txt<br/>docx<br/>xlsx<br/>pdf<br/>epub<br/><br/><b>映像</b><br/>mpg/mpeg<br/><br/><b>音声</b><br/>mp4<br/>mp3<br/>wav<br/>aiff<br/>aac<br/>flac<br/>m4a<br/>m4r<br/><br/><b>フォント</b><br/>ttf<br/>otf<br/>",
			notice: false,
			relations:[{
				group:'product',
				index:3,
			},{
				group:'product',
				index:4,
			},{
				group:'product',
				index:5,
			}],
		},
		{
			title:"販売ファイルの差し替え",
			body:"誰かに購入されるまでは何度でも変更（差し替え）が可能です。<br/><br/>誰かに購入された商品の販売ファイルを差し替えたい場合は、その商品を準備中にした上で新しい商品を登録してください",
			notice: false,
			relations:[],
		},
		{
			title:"商品価格",
			body:"商品の価格は100円～20,000円の範囲で設定できます。販売開始後も変更は可能ですが、購入者の不利益にならないように事前に告知を行うなど配慮をお願いいたします。",
			notice: false,
			relations:[],
		},
		{
			title:"著作権の扱い",
			body:"購入した商品の利用可能な範囲を商品ごとに指定できます。<br/><br/>指定できるのは「改変」「商用利用」「クレジット表記の省略」の3項目です。素材としての利用を目的とした画像や音声以外は、基本的にはすべて禁止で問題ありません。<br/><br/><hr/><b>改変</b><br/>商品の一部を改変して利用（画像に文字を追加する・トリミングするなど）<br/><br/><b>商用利用</b><br/>利益を得る目的で利用（商用作品を作る際の素材として利用するなど）<br/><br/><b>クレジット表記の省略</b><br/>作者名などを表示しないで利用<hr/><br/><br/>また、<b>二次利用・再配布は原則として禁止</b>です。<br/>(例)<br/>購入したイラストをそのまま、または一部改変したものを販売・配布する<br/>購入したイラストをそのまま、または一部改変したものをプリントしたＴシャツを販売する",
			notice: false,
			relations:[],
		},
		{
			title:"商品の削除",
			body:"誰も購入していない商品は準備中にすればいつでもすぐに削除できます。誰かに購入されたことのある商品は、お客様のダウンロード有効期間（14日）が過ぎるまで削除できません。<br/><br/>また、万が一のトラブル回避のため削除しても商品のデータは一定期間サーバーに保存されます。",
			notice: false,
			relations:[{
				group:'sale',
				index:2,
			},{
				group:'sale',
				index:3,
			}],
		},
		{
			title:"画像生成AIとの付き合い方",
			body:"MidjourneyやStableDiffusionなどの画像生成AIを利用して作った作品は、架空ストアで取り扱いできない場合があります。<br/><br/>▼取り扱いOK<br/>・AIで生成した画像の一部を加工・印刷して作品の材料にする<br/>・AIで生成した画像を本の表紙に使う<br/>・AIで生成した画像を挿絵として使う<br/>・AIで生成した画像をプロフィール画像として使う<br/><br/>▼取り扱い不可<br/>・AIで生成した画像を壁紙としてダウンロード販売する<br/>・AIで生成した画像をまとめた作品集を販売する<br/><br/>※画像の著作権や利用についての注意事項は生成に利用した各サービスでの取り決めに準ずるものとします。<br/>※上記はあくまで一例です。判断に迷う場合は事前にご相談ください。",
			notice: false,
			relations:[{
				group: 'product',
				index: 13,
			}],
		},
		{
			title:"取り扱いできない商品",
			body:"以下の商品は取り扱いができません。<br/><br/>・成人コンテンツ<br/>・情報商材<br/>・二次創作など第三者の著作権を侵害する恐れのある商品<br/>・金券（チケット）<br/>・寄付やクラウドファンディングを主目的とした商品<br/>　（例）「応援ありがとう！」とだけ書かれたテキストデータを高額で販売する、など<br/>・スパム、ウイルス、マルウェアなどダウンロードしたお客様に損害を与える商品<br/>・その他、法令若しくは公序良俗に違反するなど利用規約の禁止事項に触れる内容が含まれる商品<br/><br/>違反した商品は予告なく販売を停止する場合があります。判断の難しい場合は事前にお問い合わせください。",
			notice: false,
			relations:[{
				group: 'product',
				index: 15,
			}],
		},
		{
			title:"紹介文との不一致",
			body:"商品の紹介文と実際の内容が大きく異なる場合、予告なく販売を停止することがあります。",
			notice: false,
			relations:[],
		},
		{
			title:"ウイルスチェック",
			body:"ウイルス対策が不十分な環境からファイルをアップロードすることは固くお断りいたします。<br/><br/>アップロードされたファイルからウイルスが検知された場合には、販売を中止した上でご利用を一時制限させていただくこともありますのであらかじめご了承ください。",
			notice: false,
			relations:[{
				group: 'product',
				index: 13,
			}],
		},
	],
	"sale":[
		{
			title:"販売を開始する",
			body:"商品登録後に表示される「今すぐ販売しますか？」で「すぐ販売する」を選択するか、商品詳細画面の「商品設定→販売中にする」を選択することで販売が開始されます。<br/><br/>ただし、プロフィールの「入金情報と連絡先」が登録されていないと販売開始できませんので、登録を忘れないようにご注意ください。",
			notice: false,
			relations:[{
				group: 'profile',
				index: 2,
			}],
		},
		{
			title:"準備中にする",
			body:"商品詳細画面の「商品設定→準備中にする」を選択することで販売が休止されます。<br/><br/>準備中となっても販売ページは表示されます（購入はできません）ので、販売ページを表示したくない場合は商品の削除を行ってください。",
			notice: false,
			relations:[{
				group:'product',
				index:11,
			}],
		},
		{
			title:"販売の終了",
			body:"商品の削除を行うことで販売ページも表示されなくなります。",
			notice: false,
			relations:[{
				group:'product',
				index:11,
			}],
		},
		{
			title:"購入者の情報",
			body:"商品を購入したお客様の個人情報は一切お知らせできません。<br/><br/>ただし、法令に基づき開示することが必要である場合および裁判所、警察等公的機関から開示を求められた場合に関してはこの限りではありません。",
			notice: false,
			relations:[],
		},
		{
			title:"売上残高",
			body:"商品の売上は「売上」でリアルタイムで確認できます。購入時から7日間は商品がキャンセルされる場合もありますのであらかじめご了承ください。",
			notice: false,
			relations:[{
				group:'sale',
				index:6,
			},{
				group: 'transfer',
				index: 1,
			}],
		},
		{
			title:"注文のキャンセル",
			body:"購入者は商品の購入後7日以内であればご注文のキャンセルが可能です。ただしその商品のファイルを一つでもダウンロードした場合はキャンセル不可となります。<br/><br/>また、購入者の決済情報に不審がある場合などには商品の購入から7日以内でなくても403adamskiの判断でキャンセルを行うこともあります。",
			notice: false,
			relations:[{
				group:'sale',
				index:5,
			}],
		},
		{
			title:"特定商取引法に基づく表記",
			body:"オンライン販売に義務付けられている「特定商取引法に基づく表記」では、販売者・ショップ名として作家名（プロフィールの名前）が表示されます。<br/><br/>それ以降の項目はすべての作家さんに共通で403adamskiおよび有限会社レトロインクの情報が表示されます。<br/><br/>作家さんの本名や連絡先など<b>作家名以外の個人情報が表示されることはありません</b>のでご安心ください。",
			notice: false,
			relations:[{
				group:'profile',
				index:1,
			}],
		},
	],
	"transfer":[
		{
			title:"売上の入金時期",
			body:"売上は月末締めで翌月末の入金となります。入金先は「入金情報と連絡先」で登録された金融機関口座です。<br/><br/>振り込み日および振り込み金額は毎月8日頃に確定します。締日から振り込み金額の確定までに注文のキャンセルがあった場合は、キャンセル分を差し引いた振込金額となります。",
			notice: false,
			relations:[{
				group:'sale',
				index:5,
			},{
				group: 'transfer',
				index: 2,
			},{
				group: 'transfer',
				index: 3,
			},{
				group: 'transfer',
				index: 4,
			},{
				group: 'transfer',
				index: 5,
			}],
		},
		{
			title:"入金対象",
			body:"「入金情報と連絡先」に登録したメールアドレスの確認が済んでいない場合、入金の対象外となります。メールアドレスを登録・変更した際には早めに確認を行ってください。<br/><br/>また、連絡先に虚偽がある場合や犯罪や不正への関与が疑われる場合にも入金の対象外とすることがあります。",
			notice: false,
			relations:[{
				group: 'transfer',
				index: 1,
			},{
				group: 'transfer',
				index: 9,
			}],
		},
		{
			title:"振込手数料",
			body:"売上残高が1,000円以上の場合、振込手数料は"+TransferFee+"円<br/>売上残高が1,000円未満の場合、振込手数料は売上残高の20％です。<br/><br/>（例）売上残高6,000円の場合、振込手数料は180円<br/>　　　振込金額は6,000円-180円＝5,820円<br/>（例）売上残高800円の場合、振込手数料は800×20％＝160円<br/>　　　振込金額は800円-160円＝640円<br/><br/>※上記の金額はいずれも税込です",
			notice: false,
			relations:[],
		},
		{
			title:"最低振り込み金額",
			body:"売上残高が<b>最低振り込み金額</b>に満たない場合は入金は行われず、翌月以降に繰り越されます。<br/><br/>最低振り込み金額は"+Tools.comma(MinMinAmount)+"円～"+Tools.comma(MaxMinAmount)+"円の範囲で自由に設定できます。デフォルトでは"+Tools.comma(MinMinAmount)+"円です。",
			notice: false,
			relations:[],
		},
		{
			title:"上限金額",
			body:"振り込み金額が500,000円を超える場合は、振り込み日を調整させていただく場合があります。",
			notice: false,
			relations:[],
		},
		{
			title:"振り込みできなかった場合",
			body:"銀行口座情報の間違いなどで振り込みができなかった場合、その入金は無効となり、売上は翌月以降に繰り越されます。<br/><br/>また、正しい銀行口座の確認が済むまであらたな入金は行われません。",
			notice: false,
			relations:[],
		},
		{
			title:"売上の預かり期間",
			body:"最低振り込み金額に満たないまま繰り越しが6回続いた場合、売上残高が<b>最低振り込み金額</b>未満であっても、その月末に入金を行います。<br/><br/>その場合の振込手数料は売上残高の20％です。<br/><br/>※Amazonギフト券での支払いは終了しました。（2025年3月）",
			notice: false,
			relations:[],
		},
		{
			title:"入金口座の変更",
			body:"「入金情報と連絡先」の入金口座情報はいつでも変更できますが、すでに振り込み日と金額が確定している入金には反映されません。翌月以降の入金からの反映となります。",
			notice: false,
			relations:[],
		},
		{
			title:"不正な口座の利用",
			body:"「入金情報と連絡先」に登録されている入金口座が犯罪や不正に利用されている疑いのある場合、予告なく入金を中止することがあります。",
			notice: false,
			relations:[],
		},
	],
	"promotion":[
		{
			title:"宣伝する方法",
			body:"プロフィールの「公開ページURL」や商品の「販売ページURL」をご自身のSNSやウェブサイトで紹介して、宣伝を行ってください。<br/><br/>403adamskiで各商品や作家の宣伝を行うことは原則としてありません。各自で宣伝を行わない限り、その商品は誰にも知られないことになりますのでご注意ください。",
			notice: false,
			relations:[{
				group: 'profile',
				index: 3,
			},{
				group: 'promotion',
				index: 2,
			},{
				group: 'promotion',
				index: 4,
			},{
				group: 'promotion',
				index: 5,
			}],
		},
		{
			title:"グランドメニュー",
			body:"作家さんごとの商品リストとは別に、全作家さんの商品がズラリと表示される「グランドメニュー」が用意されています。<br/><br/>すべての商品が無条件に表示されるわけではなく、商品設定で「グランドメニューに掲載する」を選択した商品だけが対象です。<br/><br/>グランドメニューに掲載しても問題ない商品は「掲載する」を選択するようにして下さい。",
			notice: false,
			relations:[{
				group: 'promotion',
				index: 3,
			}],
		},
		{
			title:"検索タグ",
			body:"グランドメニューでは「検索タグ」を指定して商品を絞り込めます。<br/><br/>各商品の「検索タグ」は商品設定で３つまで選んで下さい。あらかじめ用意されたタグ以外に自由な言葉でタグを作成可能です。",
			notice: false,
			relations:[{
				group: 'promotion',
				index: 2,
			}],
		},
		{
			title:"ハッシュタグ",
			body:"Twitterで商品を紹介する時には、ハッシュタグ <b>#403adamski</b> をぜひご利用ください。こちらの公式アカウントでリツイートいたします。<br/><br/>ただしTwitterの不調などで捕捉しかねる場合もありますのであらかじめご了承ください。",
			notice: false,
			relations:[{
				group: 'promotion',
				index: 1,
			}],
		},
		{
			title:"スクリーンショット",
			body:"商品などを紹介する際に必要であれば403adamskiのスクリーンショットを自由に使っていただいて大丈夫です。利用できるページの制限などはありません。<br/><br/>ただし、ご自身の個人情報など公開すべきでない情報が含まれないようにくれぐれもご注意ください。",
			notice: false,
			relations:[{
				group: 'promotion',
				index: 1,
			}],
		},
	],
	"fee":[
		{
			title:"利用にかかる手数料",
			body:"商品が売れた時にだけ手数料が発生するシステムのため、登録料金や月々の利用料金は不要です。ご利用にあたり必要となる料金・手数料は以下の通りです。<br/><br/><b>販売手数料</b><br/>商品価格（税込）の3.0％<br/><br/><b>決済手数料</b><br/>商品価格（税込）の3.6％<br/><br/><b>売上の振り込み手数料</b><br/>売上残高が1,000円以上の場合…"+TransferFee+"円<br/>売上残高が1,000円未満の場合…売上残高の20％<br/><br/>販売手数料と決済手数料は注文が入るたびに、振込手数料は月1回の入金時にだけ必要となります。<br/><br/>※上記の金額はすべて税込です。<br/>※入金がない月は振込手数料も不要です。",
			notice: false,
			relations:[],
		},
	],
}

const HelpGroupName:{[key:string]:string;}  = {
	"account":"アカウント",
	"profile":"プロフィール",
	"product":"商品登録",
	"sale":"販売",
	"transfer":"売上の入金",
	"promotion":"商品の宣伝",
	"fee":"利用料金",
	"term":"利用規約",
};

const GuideDetail:React.FC<GuideDetailProps> = (props)=> {
	const classes = useStyles();
	const {helpGroup,helpIndex} = useParams<{helpGroup:string,helpIndex:string}>();

	useEffect(()=>{
		window.scrollTo(0,0);
	},[helpGroup,helpIndex]);

	if( !helpIndex || !helpGroup || !HelpDict[helpGroup] ){
		return <Navigate to='/'/>
	}
	const menus = HelpDict[helpGroup];
	const i = parseInt(helpIndex)-1;

	if( !HelpDict[helpGroup][i] ){
		return <Navigate to='/'/>
	}
	const data = menus[i];

	return (
		<React.Fragment>
			<PageTitle title="ご利用ガイド" icon="help"/>
			<Grid container spacing={3} direction="row-reverse">
				<Grid item xs={12} sm={8}>
					<h3 style={{color:'teal'}}>
						{helpGroup==='account' && <PersonIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{helpGroup==='profile' && <FaceIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{helpGroup==='product' && <UploadFileIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{helpGroup==='sale' && <CreditScoreIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{helpGroup==='transfer' && <ReceiptIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{helpGroup==='promotion' && <PublicIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{helpGroup==='fee' && <PaidIcon style={{verticalAlign:'middle',marginRight:'0.25em'}}/>}
						{data.title}
					</h3>
					<Box style={{minHeight:'20em',paddingBottom:'4em'}}>
						<Multilines text={data.body}/>
					</Box>
					<Divider/>
					{data.relations.length>0 &&
					<Box>
						<List
							subheader={
								<ListSubheader component="div" id="nested-list-subheader">
									関連記事
								</ListSubheader>
							}
						>
						{data.relations.map((relation:HelpRelation,index:number)=>{
							const key = "relation" + index;
							if( !HelpDict[relation.group] || !HelpDict[relation.group][relation.index-1] ){
								return null;
							}
							const relationData = HelpDict[relation.group][relation.index-1];
							const to = '/help/'+relation.group+'/'+relation.index;
							return (
								<Link to={to} className={classes.link}><ListItem key={key} dense>
									<ListItemText primary={relationData.title}/>
									{relationData.notice &&
									<ListItemSecondaryAction><AnnouncementIcon className="newHelp"/></ListItemSecondaryAction>
									}
								</ListItem></Link>
							);
						})}
						</List>
					</Box>
					}
				</Grid>
				<Grid item xs={12} sm={4}>
					<List
						subheader={
							<ListSubheader component="div">
								<Link to='/help' className={classes.linkGrey}>メニュー</Link> &gt; <span style={{color:'teal'}}>{HelpGroupName[helpGroup]}</span>
							</ListSubheader>
						}
					>
					{menus.map((data:HelpData,index:number)=>{
						const key = "menu" + index;
						const to = "/help/"+helpGroup+"/"+(index+1);
						return (
							<Link to={to} className={classes.link}><ListItem key={key}>
								<ListItemText primary={data.title}/>
								{data.notice &&
								<ListItemSecondaryAction><AnnouncementIcon className="newHelp"/></ListItemSecondaryAction>
								}
							</ListItem></Link>
						)
					})}
					</List>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

export default GuideDetail;